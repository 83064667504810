:root {
    --primary-color-dark: 59, 59, 59;
    --primary-green-color-rgb: 33, 153, 112;
    --primary-yellow-color-rgb: 176, 153, 37;
    --primary-blue-color-rgb: 36, 121, 159;
    --secondary-grey-color-rgb: 146, 146, 146;

    --primary-color-dark: rgba(var(--primary-color-dark), 1);

    --primary-color-1: rgba(var(--primary-color-1-rgb), 1);
    --primary-color-1-10: rgba(var(--primary-color-1-rgb), 0.1);
    --primary-color-2: rgba(var(--primary-color-2-rgb), 1);
    --primary-color-2-10: rgba(var(--primary-color-2-rgb), 0.1);
    --primary-color-3: rgba(var(--primary-color-3-rgb), 1);
    --primary-color-3-10: rgba(var(--primary-color-3-rgb), 0.1);
    --secondary-color: rgba(var(--secondary-grey-color-rgb), 1);

    --xxs-text-font-size: 10px;
    --xs-text-font-size: 14px;
    --s-text-font-size: 16px;
    --text-font-size: 17px;
    --heading-font-size: 20px;
    --l-heading-font-size: 22px;
    --xl-heading-font-size: 24px;
    --title-font-size: 60px;
    
}

h1, h2, h3, h4, h5 {
    font-family: 'Cabin', sans-serif;
    color: var(--primary-color-1);
  }

p, div, button {
    font-family: 'Cabin', sans-serif;
    font-size: var(--text-font-size) !important;
    color: var(--primary-color-dark);
}

/*All pages are wrapped by a section container, which is separated from the 
header by the following margin*/
.section-container {
    margin-top: 40px;
    margin-bottom: 80px;
}

a, .a-styled{
    display: inline;
    cursor: pointer;
    color: var(--primary-color-1);
    text-decoration: none;
}

a:hover, .a-styled:hover {
    text-decoration: none !important;
    color: var(--primary-color-1);
    font-weight: 600;
}

/*Width of the content*/
@media (min-width: 996px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 950px !important;
    }
}

/*
Div #root contains two elements (AppContainer and Footer). 
With flex, and setting the min-height to the screen height, the footer will
be always stick to the bottom of the page regardless of the content length.
*/
#root {
    display:flex;
    flex-direction:column;   
    min-height: 100vh;
  }

/*Feedback modal style*/
.feedback-modal .modal-body {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.feedback-modal iframe {
    flex: 1;
    min-height: 600px;
}

  